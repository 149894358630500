<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Reset Password</h1>


                <div v-if="!username">          
                    Incorrect link provided
                </div>
                <div v-else>
                    Sending e-mail confirmation to {{username}}...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import {toast} from 'bulma-toast'

export default {
    name: 'ResetInvite',
    data() {
        return {
            username: this.$route.query.m,
            errors: [],

        }
    },
    mounted() {
        let username = this.$route.query.m
        if (username) {
            const formData = {
                email: this.username
                }
            console.log("auto => sendReset", formData)
            this.sendReset(formData)
        }
    },
    methods: {

        async sendReset(formData){
            this.$store.commit('setIsLoading', true)
            await axios
                .post('/lms/users/reset_password/', formData)
                .then(response => {
                    console.log('reset:', response.status)

                    if (response.status === 400) {
                  
                    } else {
                        toast({
                            message: 'Confirmation e-mail sent. If not arrived, please check SPAM',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 5000,
                            position: 'center',
                            })
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast({
                                message: 'This e-mail is not registered. Please, try again',
                                type: 'is-danger',
                                dismissible: true,
                                pauseOnHover: true,
                                duration: 5000,
                                position: 'center',
                                }) 
                        } else {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        }
                    } else if (error.message) {
                        this.errors.push('Something went wrong. Please try again!')
                    }
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
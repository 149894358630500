<template>
	<div class="container">
    	<h1 class="title">Projects</h1>

        <div v-if="projects.length > 5" class="control usr_filter">
            <div class="fltr_clear" @click="usr_filter = ''" v-if="usr_filter">
                × clear
            </div>
            <input type="text" class="input" v-model="usr_filter" placeholder="Filter Projects. Start typing key word..">
        </div>

        <div v-if="projects.length === 0">
            <strong>Not found. </strong>
        </div>
        
    	<div class="pr_srchs">

    		<div class="pr_srch" 
                v-for="project in projects"
                v-bind:key="projects.id"
                > 
                <div  v-if="usr_filter.length <=2 || usr_filter.length > 2 
                    && project.title.toLowerCase().includes(usr_filter.toLowerCase())">
        
                
        			<span class="pr_srch_at">Created: {{ project.created_at.replace("T"," ").split(".")[0] }}</span>, 
        			<span class="pr_srch_by"> by {{ project.created_by }}</span>                    
        			<div class="pr_srch_q">
                        <router-link :to="{ name: 'Project', params: { id: project.id }}">
                            {{ project.title }}
                        </router-link>
                    </div>
                    <div class="pr_stco">{{project.status_comment}}</div>
                </div>
    		</div>
    	</div>       
    </div>
</template>	

<script>
    import axios from 'axios'

    export default {
        name: 'Projects',

		data() {
            return {
                projects: {},
                usr_filter: ''
            }
        },
        created() {
            this.getProjects()
        },
        /*
        mounted() {
    		if (localStorage.team_name) {
      			this.team_name = localStorage.team_name
    		}
    	},
        */
        methods: {
            async getProjects() {
		    	this.$store.commit('setIsLoading', true)

                await axios
                	.get('/lms/projects')
                    .then(response => {
                    	//console.log("projects", response.data)
                        
                        this.projects = response.data
                    })

                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })
                
		    	this.$store.commit('setIsLoading', false)
		    },
        }
    }
</script>

<style>
	.pr_srch {
		margin:12px;
		/* border-bottom: 1px solid #aaa; */
		font-size: 12px;
		color:  #777;
	}

	.pr_srch_q {
		display:  block;
		font-size: 18px;
		font-weight: 600;
	}

    .pr_stco {
        font-weight: 600;
        color: #a66;
    }

    .usr_filter {
        max-width: 360px;
        font-size: 12px;
        color: #999;
    }

    .fltr_clear {
        float: right;
        color:  #c44;
        border:  0;
        background: transparent;
    }

    .fltr_clear:hover {
            
            cursor: pointer;
        } 



	

</style>
<template>

<br />
<a href="/sign-up" class="button is-success"><b>Sign Up</b></a>&nbsp;
<a href="/log-in" class="button">Log In </a>
<div style="font-size: smaller">No credit card required</div>

<h2 class="title" style="text-align: center">Want to see B2BSearch in action?</h2>

<p style="text-align: center">Watch a 2-minute demo:</p>

<div class="embed-responsive embed-responsive-16by9" style="text-align: center">
    <iframe class="embed-responsive-item" 
        src="https://www.youtube.com/embed/WP3AMQ_cWHA?autoplay=1" 
        allowfullscreen="allowfullscreen"
        controls="0">
    </iframe>
</div>
<br />


<button class="accordion" style="text-align: center">Why B2BSearch?</button>
<div class="panel">
    <br />
    <p>Searching for relevant prospects is not an easy task, but rather one that consumes much time and effort. It can also make you quite frustrated with the results. </p>

    <p>Here are some most common prospecting problems related to inefficiency of standard sourcing methods that you may have experienced:</p>

    <ul>
        <li>• Search workflow process is multi-step and confusing</li>
        <li>• Access to credible data sources is restricted by payment plans</li>
        <li>• Contact enrichment is limited to a LinkedIn profile and an occasional email </li>
        <li>• Many data sources including LinkedIn have outdated profiles </li>
        <li>• Team collaboration functionality within lead source platforms is scarce </li>
    </ul>
    <p><strong>B2BSearch transforms that experience and makes the lead generation process easier and more efficient.</strong></p>
    <br />
</div>


<h2 class="title" style="text-align: center">B2BSearch automates prospect discovery and outreach for greater productivity</h2>

<br />
<p style="text-align: center">Explore the innovative shortcut to making a list of highly qualified candidates with accurate contact information</p>
    
<br />

<button class="accordion">
    Search As Specificly As You Need
</button>
<div class="panel">
    <br />
    <p>With B2BSearch, you can reduce the number of your workflow steps required to find a perfect candidate by narrowing down the search right from the start. Enter any or all specific attributes critical to the open role into the search box and get instant relevant results. For example, "senior full stack web developer python" and "Glasgow" as a location will instantly return a list of matching candidates. Or, using "tensorflow" as a single keyword will produce results for data scientists, machine learning engineers or AI developers who use this specific ML software package.</p>
    <br />
</div>

<button class="accordion">
    Pre-Qualify Prospects Easier
</button>
<div class="panel">
    <br />
    <p>B2BSearch is equipped with the contact enrichment tool to let you get additional information and links related to prospect profiles. Social network profiles, personal and corporate websites, as well as other pieces of information you might find useful for your lead qualification.</p>
    <br />
</div>


<button class="accordion">
    Get Valid Contact Info in Seconds
</button>
<div class="panel">
    <br />
    <p>Finding a prospect's contact information has always been a struggle. With B2BSearch, you can access accurate and verified contact details for each prospect in a list with just one click. Our AI search algorithm has been developed to extract most up to date emails and social profiles from multiple data sources in a fraction of time.</p>
    <br />
</div>


<button class="accordion">
    Automate Your Outreach
</button>
<div class="panel">
    <br />
    <p>Message pre-fill feature saves you from copy-pasting email messages whenever you wish to reach a prospect. Create your message once and use it as many times as you need moving along your prospect list.</p>
    <br />
</div>


<button class="accordion">
    Keep Your Team In The Know
</button>
<div class="panel">
    <br />
    <p>B2BSearch was built for teamwork and collaboration. The platform allows for easier onboarding to new team members and introducing them to the process. Create research projects, invite participants, work on your prospect lists simultaneously with your colleagues and get better results!</p>
    <br />
</div>










<br />
<h2 class="title" style="text-align: center">
    Search more efficiently and build a highly targeted prospect list with B2BSearch
</h2>

<br />
<center>
<a style="text-align: center" href="/sign-up" class="button is-success">Sign up now for free</a>
</center>
<br />
<br />

</template>

<script>

    import ContentBlock from '@/components/services/ContentBlock'

    export default {
        name: 'HomeGuest',
        components: {ContentBlock},
        mounted() {
            //this is the button
            var acc = document.getElementsByClassName("accordion");
            var i;
            console.log('accordion', acc)
            for (i = 0; i < acc.length; i++) {
                //when one of the buttons are clicked run this function
                console.log('accordion elems', i, acc[i])
              acc[i].onclick = function() {
                console.log('click')
                //variables
                var panel = this.nextElementSibling;
                var coursePanel = document.getElementsByClassName("panel");
                var courseAccordion = document.getElementsByClassName("accordion");
                var courseAccordionActive = document.getElementsByClassName("accordion active");

                /*if pannel is already open - minimize*/
                if (panel.style.maxHeight){
                    //minifies current pannel if already open
                    panel.style.maxHeight = null;
                    //removes the 'active' class as toggle didnt work on browsers minus chrome
                    this.classList.remove("active");
                } else { //pannel isnt open...
                    //goes through the buttons and removes the 'active' css (+ and -)
                    for (var ii = 0; ii < courseAccordionActive.length; ii++) {
                        courseAccordionActive[ii].classList.remove("active");
                    }
                    //Goes through and removes 'activ' from the css, also minifies any 'panels' that might be open
                    for (var iii = 0; iii < coursePanel.length; iii++) {
                      this.classList.remove("active");
                      coursePanel[iii].style.maxHeight = null;
                    }
                  //opens the specified pannel
                  panel.style.maxHeight = panel.scrollHeight + "px";
                  //adds the 'active' addition to the css.
                  this.classList.add("active");
                } 
              }//closing to the acc onclick function
            }//closing to the for loop.
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
    // ------------------- 3 col table ----------------------
        .embed-responsive {
            position: relative;
            display: block;
            height: 0;
            padding: 0;
            overflow: hidden;
        }

        .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
        .embed-responsive-16by9 {
            padding-bottom: 56%;
        }
        
        .forcols {
            background-color: #f0f0f0;
            border-block: solid;
        }
        
        .fullw {
          box-shadow: 0 0 0 100vmax #f0f0f0;
          clip-path: inset(0 -100vmax); 
        }
    // ------------------- 3col table----------------------

    // ------------------- accordion ----------------------

.accordion {
   
    cursor: pointer;
    padding: 8px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 1.2em;
    transition: 0.4s;
    line-height: 1.5em;
    font-weight: 600;
}

/*When the button is active or mouse hovers*/
.accordion.active,
.accordion:hover {
    background-color: rgba(166,166,166,0.6);
}

/*button not active*/
.accordion:after {
    content: '\002B';
    font-weight: 800;
    float: right;
    margin-left: 5px;
}

/* minus button */
.accordion.active:after {
    content: "\2212";
}

div.panel {
    padding: 0 18px;
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    line-height: 1.6em;
    letter-spacing: .4px;
    font-weight: 400;
    font-style: normal;
    color: rgba(0,0,0,.88);
}

    // ------------------- accordion ----------------------
</style>
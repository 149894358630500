<template>
	<!-- legacy component, not used -->
	<!--<button class="button" v-if="project">to E-mail Project</button>-->
	<button v-if="results.project_email" class="button" @click="toEmailProject">
		to E-mail Project
	</button>
	<button v-else class="button is-primary" @click="createEmailProject">
		Create E-mail Project
	</button>
</template>

<script>
	import axios from 'axios'

    export default {
    	name: 'EmailProjectCreate',
    	props: {
    		results: '',
    	},
        mounted() {
            //this.project_html()
        },
    	methods:{
		    toEmailProject() {
		    	console.log('EmailProjectCreate response', this.results)
		    	this.$router.push(`/project/${this.results.project_email}`)
		    },
		    async createEmailProject() {
		        this.$store.commit('setIsLoading', true)
		        let request = {"search_id": this.results.id}
		        console.log("EmailProject request:", request)
		        
		        await axios
		            .post('/lms/project/email/create', request)
		            .then(response => {
		                this.project = response.data
		                console.log("this.project", this.project)
		                this.$router.push(`/project/${this.project.project}`)
		            })
		            .catch(error => {
                        console.log(error)
                    })
		        
		        this.$store.commit('setIsLoading', false)
		    },
		},
	}
</script>
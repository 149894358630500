<template>
    <div class="container">
        <div class="columns is-multiline">
			<div class="column is-11 is-offset-1">
				<h1 class="title">Actions</h1>
				<table class="table">
					<thead>
						<tr>
							<th>Date, Time</th>
							<th>User</th>
							<th>Action</th>
							<th colspan="2"> </th>
							<th>Credits spent</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="action in actions">
							<td>{{action.created_at.replace("T"," ").split(".")[0]}}</td>
							<td>{{action.created_by}}</td>
							<td>{{action.details}}</td>
							<td>{{action.related_obj_type}}</td>
							<td>{{action.related_obj_id}}</td>
							<td>{{action.credits}}</td>
						</tr>
					</tbody>
				</table>
			</div>
        </div>
    </div>

</template>

<script>
	import axios from 'axios'
	import SaveCsv from '@/components/services/SaveCsv'
	

    export default {
    	name: 'Actions',
    	
    	data(){return{
			actions: []
		}},
        created() {
            this.getActions()
        },
    	methods:{
		    async getActions() {
		        this.$store.commit('setIsLoading', true)
		        this.$store.state.isProjLeadsEmail = false

		        const projectID = this.$route.params.id

		        await axios
		            .get(`/lms/actions`)
		            .then(response => {
		            	console.log(response.data)
		                this.actions = response.data
		            })
		            .catch(error => {
                        console.log(error)
                    })
		        
		        this.$store.commit('setIsLoading', false)
		    },
		},
	}
</script>

<style>
td{
	padding-right: 8px;
	font-size: smaller;
}
</style>
<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
         <div class="column is-6 is-offset-2">
          <b>b2bSearch</b> by:
          <br/>B2B Research
          <br/>Rue de Montbrillant 26, 1201, Geneve, Suisse
          <br/><a href=mailto:info@b2bsearch.co>info@b2bsearch.co</a>
        </div>
        <div class="column is-4">
          <a href="/pub/terms">Terms of service</a>
          <br/><a href="/pub/privacy">Privacy policy</a>
        </div>
        
      </div>
    </div>
  </footer>
</template>

<style>
  
</style>
<template>
	<button class="button" @click="saveXlsx">
		<u>&#8681;</u>&nbsp;XLSX 
		<span v-if="!project.mail_list_status" class="credits1">{{PRICE}}</span>
	</button>
</template>

<script>
	import axios from 'axios'

	export default {
        name: 'SaveXls',
        data() {
            return {
                xlsFN: '',
                PRICE: 20
            }
        },
        props: {
        	project: {},
        },
        
        methods: {
        	async saveXlsx() {
                this.$store.commit('setIsLoading', true)  
                await axios
                	.get(`/lms/project/${this.project.id}/xlsx`, this.project)
                    .then(response => {
                    	this.$store.state.teamCredits =  response.data.team_credits
                    	// console.log('saveXls response.data.team_credits', response.data)
                    	if(response.data.credit_status === false) {
		                   	this.$store.state.creditsOut = true
		                   	this.$store.commit('setIsLoading', false)
		                   	return {'error': 'Not enough credits'}
		                } else {
	                    	console.log('saveXls response', response.data.team_credits)
							let redirUrl =  process.env.VUE_APP_XLS_TMP_DIR+response.data.link
							console.log('VUE_APP_XLS_TMP_DIR', process.env.VUE_APP_XLS_TMP_DIR)
							//console.log('redirUrl', redirUrl)
							window.location.replace(redirUrl)
						}
					})
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },
    }
</script>
<template>

    <div class="embed-responsive embed-responsive-16by9">

        <iframe class="embed-responsive-item" 
            src="https://www.youtube.com/embed/bI2-BE6u5Eo" 
            allowfullscreen="allowfullscreen"
            controls="0">
        </iframe>

    </div>

</template>
<script>
	export default {
        name: 'VideoIntro',
    }
</script>
<style>
		.embed-responsive {
		    position: relative;
		    display: block;
		    height: 0;
		    padding: 0;
		    overflow: hidden;
		}

		.embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    border: 0;
		}
		.embed-responsive-16by9 {
		    padding-bottom: 56%;
		}
</style>
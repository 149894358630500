<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Reset Password</h1>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Your e-mail:</label>
                        <div class="control">
                            <input id="eml" type="email" name="email" class="input" v-model="username">
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Submit</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import {toast} from 'bulma-toast'

export default {
    name: 'ResetPassword',
    data() {
        return {
            errors: [],

        }
    },

    methods: {

        async submitForm() {
            this.errors = []

            console.log(this.username)

            if (this.username === '') {
                this.errors.push('Invalid e-mail')
            }

            console.log("this.errors", this.errors)
            if (!this.errors.length) {
                //this.$store.commit('setIsLoading', true)

                /*const formData = {
                    email: this.username
                }*/

            }
            //sendReset(formData)
            this.sendReset(this.username)
        },

        async sendReset(user_mail){
            this.$store.commit('setIsLoading', true)
            await axios
                //.post('/lms/users/reset_password/', formData)
                .post('/lms/users/reset_password/', {email: user_mail})
                .then(response => {
                    console.log('reset:', response.status)

                    if (response.status === 400) {
                  
                    } else {
                        toast({
                            message: 'Confirmation e-mail sent. If not arrived, please check SPAM',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 5000,
                            position: 'center',
                            })
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            toast({
                                message: 'This e-mail is not registered. Please, try again',
                                type: 'is-danger',
                                dismissible: true,
                                pauseOnHover: true,
                                duration: 5000,
                                position: 'center',
                                }) 
                        } else {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        }
                    } else if (error.message) {
                        this.errors.push('Something went wrong. Please try again!')
                    }
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
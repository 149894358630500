<template>
	<h1 class="title is-1">Test template</h1>

<hr />
  <section class="section">
    <div class="container">
      <h1 class="title">Bulma Modal Confirmation</h1>
      <p class="subtitle">Displays a modal when button pressed</p>
      <button class="button" @click="showModal">Show Modal</button>
      <hr />
      <p>Last Modal Confirmation: {{ okPressed }}</p>
    </div>




    <div class="modal" :class="{'is-active': showModalFlag}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirmation Modal</p>
          <button class="delete" aria-label="close" @click="cancelModal">></button>
        </header>
        <section class="modal-card-body">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam laoreet enim a est hendrerit vulputate. Aliquam tincidunt tellus non ultricies tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed eu erat ac magna imperdiet feugiat nec non nisl. Integer at justo euismod, gravida ex sit amet, gravida felis. Fusce sed nisl diam. Aenean sapien ipsum, fringilla ac justo in, tempor pulvinar urna. In hac habitasse platea dictumst. Etiam dolor sem, semper sit amet varius nec, congue sit amet mauris. In posuere pharetra leo in mollis. Mauris vitae erat eget risus mollis laoreet. Fusce finibus, eros consequat ornare condimentum, diam dui aliquet neque, et viverra purus justo ac dui. Curabitur magna nibh, rutrum eu molestie sit amet, suscipit ac ipsum. Vivamus vitae nisl facilisis, pharetra nunc id, ultrices tortor. Proin porta ex quis pellentesque commodo.</p>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="okModal">Ok</button>
          <button class="button" @click="cancelModal">Cancel</button>
        </footer>
      </div>
    </div>
  </section>


<hr />

<h4 class="title is-4">Input variuos bindings for mobile phone keyboard</h4>
	  
	<h3 class="title is-3">Event target value:</h3>	
	  <div>
	    <input type="ext"
	        :value="message1"
	        @input="evt=>message1=evt.target.value"
	        >
	    <div>
	    	From form: {{message1}}
	    </div>
	    <div>
	    	From JS: {{message2}} 
	    </div>
	  </div>


	  <!----------------------------------------------------------->
	  <!-- variant 6 
	    compositionupdate to provide compatibility with Android keyboards with typeaheads
	    https://stackoverflow.com/questions/50617865/vue-v-model-input-change-mobile-chrome-not-work
	    !!! not working
	    -->
	    <hr>
	    <h3 class="title is-3">Composition update:</h3>
	    <input type="text" v-model="myinputbox" @compositionupdate="compositionUpdate($event)">
	    <input type="text" v-model="myinputbox" @compositionupdate="compositionupdate">
	    <div>
	    	{{myinputbox}}
	    </div>
	    <!-- /variant 6 -->
	    <!----------------------------------------------------------->




</template>

<script>
/*
  data: {
    showModalFlag: false,
    okPressed: false,
    message: "Press 'Ok' or 'Cancel'."
  },
  */
export default {
    name: 'Test',
    data() {
        return {
		    showModalFlag: false,
		    okPressed: false,
		    message: "Press 'Ok' or 'Cancel'.",


            message1:'',
            message2:'',
            myinputbox:'',
        }
    },
    watch: {
    	message1() {
    		console.log(this.message1)
    		this.message2 = this.message1
    	},
    	//variant 6
	    compositionUpdate(event) {
	        this.myinputbox = this.event.data;
	        console.log("comp_upd", this.myinputbox)
	    },
    },
    methods: {
    	/* modal window scripts */
	    showModal() {
	      this.okPressed = false;
	      this.showModalFlag = true;
	    },
	    okModal() {
	      this.okPressed = true;
	      this.showModalFlag = false;
	    },
	    cancelModal() {
	      this.okPressed = false;
	      this.showModalFlag = false;
	    }
	    /* end - modal window scripts */

    /*compositionupdate: function(event)
	    {
	    	console.log(event.data)
	        this.myinputbox = event.data;
	    },*/
          
    }
}
        
</script>
<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">My team: {{ team.name }}</h1>

                

                <!--
                <p><strong>Plan: </strong>{{ $store.state.team.plan }}</p>
                <p><strong>Max clients: </strong>{{ $store.state.team.max_clients }}</p>
                <p><strong>Max leads: </strong>{{ $store.state.team.max_leads }}</p>
                <p v-if="$store.state.team.plan !== 'Free'"><strong>Plan end date: </strong>{{ team.plan_end_date }}</p>
                
                <p>
                    <router-link :to="{'name': 'Plans'}">Change plan</router-link>
                </p>
                -->


                <br/>
                <h2 class="subtitle">Members:</h2>


                <div
                    v-for="member in team.members"
                    v-bind:key="member.id"
                >
                    <span class="team_uname">[ {{ member.username }}] </span>
                    <span class="team_fn"> {{ member.first_name }} {{ member.last_name }}</span>
                </div>

                <br/>
                <template v-if="team.created_by.id === parseInt($store.state.user.id)">
                    <router-link :to="{'name': 'AddMember'}" class="button is-primary">Invite new member</router-link>
                </template>

                <div style="margin-top: 24px;">
                    <router-link to="/team-searches">My Team Searches</router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Team',
    data() {
        return {
            team: {
                members: [],
                created_by: {}
            },
            me: {
                username: ''
            }
        }
    },
    mounted() {
        this.getMe()
        this.getTeam()
    },
    methods: {
        async getMe() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/lms/users/me/')
                .then(response => {
                    this.me = response.data
                    console.log("ME", this.me)
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        },
        async getTeam() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/lms/teams/get_my_team/')
                .then(response => {
                    this.team = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            
            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>
<template>
	<button style="min-width: 150px; background-color: #ddd;" class="button cb1" @click="getContent">
		{{cbtn.title}} {{isopen ? '&#9650;' : '&#9660;'}}
	</button>

	<div v-if='isopen' v-html="content" class="cntbtn content" :style="{ padding: cbtn.padding}"></div>

</template>

<script>
    import axios from 'axios'

    export default {
    	name: 'ContentButton',
    	props: {
    		cbtn: {
	    		title: '',
	    		url: '',
	    		padding: ''
    		}

    	},
    	data() { return {
				content: null,
				isopen: false
    	}},
    	mounted() {
            this.checkContent()
        },
    	methods:{
            async getContent() {
            	if (this.isopen) {
            		this.isopen = false
            	} else {
            		if (this.content) {
            			this.isopen = true
            		} else {
		                this.$store.commit('setIsLoading', true)

		                const url1 =  this.cbtn.url
		                await axios
		                    .get(`/lms/contentblock/${url1}/`)
		                    .then(response => {
		                        // console.log(response)
		                        this.content = response.data.content
		                        this.isopen = true
		                    })
		                    .catch(error => {
		                        console.log(error)
		                    })
		           
		                this.$store.commit('setIsLoading', false)
		            }
	            }	
            },
            async checkContent() {
            	console.log('content', this.content)
            }
		}
	}
</script>
<style>
	.box1 {
		border-bottom: 1px dotted #999;
		padding: 0 1vw;
		background-color: #fcfcfc;
		border-radius: 4px;
		margin:2px 0;

	}
	.cb1 {
		margin-right: 12px;	
		margin-bottom: 8px;
	}
	.cntbtn {
		background-color: #eee;
		border: 1px solid #bbb;
		border-radius: 6px;	
		
	}


</style>

<template>
    <!-- https://courses.bekwam.net/public_tutorials/bkcourse_vuejs_bulma_modal_1.html -->
    <div class="modal" :class="{'is-active': showModalFlag}">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Edit {{ lead.contact_person }}</p>
              <!-- <button class="delete" aria-label="close" @click="cancelModal">></button> -->
            </header>
            <section class="modal-card-body">            
                <!-- <form @submit.prevent="submitForm"> -->
                <form>
                    <div class="field">
                        <label>Contact person</label>
                        <div class="control">
                            <input type="text" class="input" v-model="lead.contact_person">
                        </div>
                    </div>
                    <div class="field">
                        <label>Job/position</label>
                        <div class="control">
                            <input type="text" class="input" v-model="lead.job">
                        </div>
                    </div>
                    <div class="field">
                        <label>Company</label>
                        <div class="control">
                            <input type="text" class="input" v-model="lead.company">
                        </div>
                    </div>
                    <div class="field">
                        <label>Location</label>
                        <div class="control">
                            <input type="text" class="input" v-model="lead.loc">
                        </div>
                    </div>
                    <!--
                    <div class="field">
                        <label>Status</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="lead.status">
                                    <option value="new">New</option>
                                    <option value="contacted">Contacted</option>
                                    <option value="inprogress">In progress</option>
                                    <option value="lost">Lost</option>
                                    <option value="won">Won</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    -->
                    <div class="field">
                        <label>Priority</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="lead.priority">
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label>Brief:</label>
                        <div class="control">
                            <textarea rows="4" class="textarea" v-model="lead.snippet"></textarea>
                        </div>
                    </div>

                    <div class="field">
                        <label>Notes:</label>
                        <div class="control">
                            <textarea rows="12" class="textarea" v-model="lead.info"></textarea>
                        </div>
                    </div>
                </form>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="submitForm">Save</button>
                <button class="button" @click="cancelModal">Cancel</button>
            </footer>
        </div>
    </div>

    <button class="button" @click="editModal">&#x1F4DD; Notes / Edit</button>
</template>

<script>
    import axios from 'axios'

    import { toast } from 'bulma-toast'

    export default {
        name: 'LeadEditModal',
        props: {
            lead: '',
        },
        //emits: ['canceledit'],
        data() {
            return {
                showModalFlag: false,
                lead_backup: {},
                team: {
                    members: []
                }
            }
        },
        
        methods: {
            editModal() {
                this.showModalFlag = true;
                this.lead_backup = JSON.parse(JSON.stringify(this.lead))
                //console.log("editModal lead_backup", this.lead_backup)
            },
            cancelModal() {
                //console.log("lead_backup", this.lead_backup.contact_person)
                for(var k in this.lead_backup) this.lead[k]=this.lead_backup[k]
                this.showModalFlag = false
            },
            async submitForm() {
                this.$store.commit('setIsLoading', true)
        
                await axios
                    .patch(`/lms/leads/${this.lead.id}/`, this.lead)
                    .then(response => {
                        toast({
                            message: 'Saved',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 1200,
                            position: 'center',
                        })
                        this.showModalFlag = false;                       
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
        }
    }
</script>
<style>
    textarea { resize: vertical; }
    .modal-background {
    background-color: rgba(10, 10, 10, 0.46) !important;
}
</style>
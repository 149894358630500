<template>
    <div class="modal" :class="{'is-active': showModalFlag}">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Search Results Preview: {{result.persons.length}} found</p>
              <!-- <button class="delete" aria-label="close" @click="cancelModal"></button> -->
            </header>
            <section class="modal-card-body"> 
                <!-- pre>{{result.persons}}</pre -->
                
                <div v-if="result.rejected">
                    <h1 class="title">Not found</h1>
                </div>

                <div v-else class="results">
                    <div class="prevhint">
                        Click <span class="nobr">[Accept results] below</span> to start working with profiles found if the results look relevant.
                    </div>
                    <div class="res_pf" v-for="item in result.persons" :key="item.id">
                        <div class="pf"> 
                            <div class="pf_name">
                                <strong>{{ item.name }}</strong>
                            </div>
                            <div class="pf_job" v-if="item.job">
                                <em>Job title:</em> {{ item.job }}
                            </div>
                            <div class="pf_company" v-if="item.company">
                                <em>Company:</em> {{ item.company }}
                            </div>
                            
                            <div class="pf_loc" v-if="item.loc">
                                Location: {{ item.loc }}
                            </div>
                            <div class="pf_snip" v-if="item.snippet">
                                {{ item.snippet }}
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <footer class="modal-card-foot">
                <button  :class="{'button':true, 'is-primary':true, 'is-loading':prjClicked}" @click="createProject">Accept Results</button>
                <button class="button" @click="cancelResult">Discard</button>
            </footer>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'SearchResultsPreview',
        props: {result: false},
        emits: ['cancelresevt'],
		data() {
            return {
                showModalFlag: true,
                prjClicked: false,
            }
        },
        methods: {
            cancelResult () {
                //this.result = false
                this.$emit('cancelresevt')
                this.showModalFlag = false
            },
            async createProject() {
                this.prjClicked = true
                this.$store.commit('setIsLoading', true)
                //console.log("search id:", this.result.search_id)
                
                await axios
                    .post('/lms/project/create', {"search_id":this.result.search_id})
                    .then(response => {
                        this.project_id = response.data
                        //console.log("this.project_id", this.project_id)
                        this.$router.push(`/project/${this.project_id.project_id}`)
                    })
                    .catch(error => {
                        console.log(error)
                    })
                
                this.$store.commit('setIsLoading', false)
            }
        }
    }
    
</script>
<style>
    .pse-bttn {
        display: inline-block;
        justify-content: center;
        padding-bottom: calc(0.5em - 1px);
        padding-left: 1em;
        padding-right: 1em;
        padding-top: calc(0.5em - 1px);
        text-align: center;
        white-space: nowrap;
    }
    .sm {
        font-size: smaller;
        color:  #777;
    }
    .res_pf {
        margin:12px;
        border-bottom: 1px solid #aaa;
        min-height:160px;
    }
    .pf_snip {
        border-top: 1px dashed #aaa ;
        font-size: smaller;
    }

    .sr-hints {
        padding: 1.25rem;
        margin-top: 12px;
    }
    .hint{
        display: inline-block;
        padding:  9px;
        color:  #999;
        font-size: smaller;
    }
    .nobr {
        white-space: nowrap;
    }
    .prevhint{
        color: #c33;
        /*border: 2px solid #c33;*/
        padding: 1rem;
        font-weight: 600;
    }

</style>
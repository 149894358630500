<template>
    <div class="content" v-html="content"></div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'ContentBlock',
        props: {
            cblk: { 
                url:'a',
            },
        },

        data() {
            return {
                content: '',
            }
        },
        
        mounted() {
            this.getContentBlock()
        },
        methods: {
            async getContentBlock() {
                this.$store.commit('setIsLoading', true)
                const url1 = this.cblk.url
                console.log('contentblock', this.cblk.url)
                await axios
                    .get(`/lms/contentblock/${url1}/`)
                    .then(response => {
                        console.log(response)

                        this.content = response.data.content
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)

            }

        },
    }
</script>

<style>
    h2,h3,h4 {
        margin-top: 20px;
    }
    
</style>
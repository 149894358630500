<template>
    <!-- not used, may be useful if it will be many messages in project -->
    <button class="button" @click="msgHidden = !msgHidden">{{msgHidden ? 'Messages prefill' : 'Hide Messages prefill'}}</button> 
    <div v-if="!msgHidden">
        <form @change="saveMessage">
            <div class="field">
                <label class="sm">Message Subject</label>
                <div class="control">
                    <input type="text" class="input" 
                        placeholder="Subject for messages created"
                        v-bind="msg_subject"
                    >
                </div>
            </div>
            <div class="field">
                <label class="sm">Message Body</label>
                <div class="control">
                    <textarea rows="4" class="textarea" 
                        placeholder="Body for messages created"
                        v-bind="msg_body" 
                    >{{msg_body}}</textarea>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    export default {
        name: 'ProjectMessage',
        props: {
            msg_subject: '',
            msg_body:'',
        },
        data(){return{
            msgHidden: true,
            messageFlag: false,
            message: {},
        }},
        methods: {
            async messageSave() {
                console.log('message save', this.msg.id)
                await axios
                    .post(`/lms/project/email/${this.message_id}/update`, this.message)
                    .then(response => {
                        this.emp_save_rslt=response.data
                        toast({
                            message: 'Message Saved',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 1200,
                            position: 'bottom-right',
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })      
            },
        }
    }
</script>
<style>
    textarea { resize: vertical; }
    .modal-background {
    background-color: rgba(10, 10, 10, 0.46) !important;
}
</style>
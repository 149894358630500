<template>

	<div class="pf_more_links" v-if="item.g_links">
		Web links:
	    <ul v-for="glink in item.g_links">
	        <li><a :href="glink" target="blank">{{glink}}</a></li>
	     </ul>
	</div>

	<button v-else class="button" @click="lightSearch">&#x1F30D; Get web links</button>
</template>

<script>
    import axios from 'axios'

    export default {
    	name: 'LightSearch',
    	props: {
    		item: {},

    	},
    	methods:{
		    async lightSearch() {
		        this.$store.commit('setIsLoading', true)
		        //console.log("item:", this.item)
		        
		        const personInfo = {
		            name: this.item.name ? this.item.name : this.item.contact_person,
		            job_title: this.item.company //company provides better results than job title

		        }
		        
		        
		        await axios
		            .post('/lms/search/ligth/', personInfo)
		            .then(response => {
		                
		                this.item.g_links = response.data.links
		                console.log("response", this.item.g_links)
		                })
		        
		        this.$store.commit('setIsLoading', false)
		    }
		}
	}
</script>


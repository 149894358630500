<template>
  <div class="container">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="home">
        	<!--<AdvSearch v-if="$store.state.isAuthenticated"></AdvSearch>-->
          <SaLinkedin2209 v-if="$store.state.isAuthenticated" />
          <HomeGuest v-else />
        </div>

        <br><br>
        <!--p><strong>Learning video:</strong></p>
        <br>
        <VideoIntro /-->
      </div>
    </div>
  </div>
</template>
<script>
  import HomeGuest from '@/components/HomeGuest.vue'
  //import Search from '@/components/Search.vue'
  //import AdvSearch from '@/views/AdvSearch.vue'
  import SaLinkedin2209 from '@/views/SaLinkedin2209.vue'
  //import SaLinkedin2209 from '@/views/SaLinkedin2209_multi_inputs.vue'
  import VideoIntro from '@/components/services/VideoIntro'




  export default {
    name: 'Home',
    components: {
      HomeGuest,
      SaLinkedin2209,
      VideoIntro
    }
  }
</script>

<template>
	<div class="container">
        <div class="columns">
            <div class="column is-4 is-offset-2">
            	
    			<h1 class="title">Email Confirmation</h1>
    			
    			<p>Activation email sent to address provided.</p>
    			<p>&nbsp;</p>
    			<p><b>Please go to your mailbox and click the link in an email</b> from b2bSearch to activate the account created.</p>
    			<p>&nbsp;</p>
    			<p>If your email has not arrived within minutes, please check the SPAM folder.</p>		

			</div>
		</div>
	</div>
</template>

<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Invite team member</h1>
            </div>
            <!--
            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" name="email" class="input" v-model="username">
                        </div>
                    </div>


                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            -->



            <div class="column is-12">
                <form @submit.prevent="submitForm2">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" name="email" class="input" v-model="username">
                        </div>
                    </div>


                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Submit</button>
                        </div>
                    </div>
                </form>
            </div>




        </div>
    </div>
</template>

<script>
import axios from 'axios'

import {toast} from 'bulma-toast'

export default {
    name: 'AddMember',
    data() {
        return {
            username: '',
            password1: '',
            password2: '',
            errors: []
        }
    },
    methods: {
        /*
        async submitForm() {
            this.errors = []

            if (this.username === '') {
                this.errors.push('The username is missing')
            }


            if (!this.errors.length) {
                this.$store.commit('setIsLoading', true)

                let pwd = 'a' + Math.random().toString(8).slice(-8)

                const formData = {
                    username: this.username,
                    password: pwd,
                    re_password: pwd,
                    email: this.username
                }
                console.log(formData)
                await axios
                    .post('/lms/users/', formData)
                    .then(response => {
                        toast({
                            message: 'The member was added, invitation e-mail sent',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'center',
                        })

                        const emailData = {'email': this.username,
                                            'pwd': pwd}

                        axios
                            .post('/lms/teams/add_member/', emailData)
                            .then(response => {
                                this.$router.push({'name': 'Team'})
                            })
                            .catch(error => {
                                if (error.response) {
                                    for (const property in error.response.data) {
                                        this.errors.push(`${property}: ${error.response.data[property]}`)
                                    }
                                } else if (error.message) {
                                    this.errors.push('Something went wrong. Please try again!')
                                }
                            })
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })

                this.$store.commit('setIsLoading', false)
            }
        },
        */



        async submitForm2() {
            this.errors = []

            if (this.username === '') {
                this.errors.push('The username is missing')
            }


            if (!this.errors.length) {
                this.$store.commit('setIsLoading', true)

                const formData = {
                    username: this.username,
                }
                console.log(formData)
                await axios
                    .post('/lms/add_user_dj/', formData)
                    .then(response => {
                        toast({
                            message: 'The member was added, invitation e-mail sent',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'center',
                            })
                        this.$router.push('/dashboard/team')
                        })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })

                this.$store.commit('setIsLoading', false)
            }
        }
    }
}
</script>
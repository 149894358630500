<template>
    <div class="container">

        <div v-if="results.rejected">
            <h1 class="title">Not found</h1>
        </div>

        <div v-else class="results">
            <div>
                <span class="sm">Created: </span>{{ results.created_at.replace("T"," ").split(".")[0] }}, 
                <span class="sm">by</span> {{ results.created_by }}
            </div>

            <h1 class="title">{{results.query}}</h1>

            <div v-if="results.result.number_of_results !== 0" class="ucases buttons">
                <ProjectEmailCreate :results="results" />
                <span class="pse-bttn">Download:</span><button class="button" @click="xlsExport()">XLSX</button>
                <SaveCsv :results="results" />
                

                <div class="sr-hints">
                    Create individual leads to get your finds' real emails, enrich their personal profiles with other relevant links and manage their prospect status
                </div>
            </div>
            <div v-else>
                No actions can be made with empty search results<br><br>
            </div>
            <div>
                Displayed <strong>{{ results.result.persons.length }}</strong> of 
                <strong>{{results.result.number_of_results}} profiles</strong> found
            </div>


            
            <div class="res_pf" v-for="item in results.result.persons" :key="item.id">
                
                <div class="pf_photo_w">
                    <img class="pf_photo" :src="item.image" v-if="item.image" />
                </div>
                <div class="pf">
                    
                    <div class="pf_name">
                        <strong>{{ item.name }}</strong>

                    </div>
                    
                    <div class="pf_job" v-if="item.job">
                        Job title: {{ item.job }}
                    </div>
                    <div class="pf_company" v-if="item.company">
                        Company: {{ item.company }}
                    </div>
                    <div class="pf_loc" v-if="item.location">
                        Location: {{ item.location }}
                    </div>
                    <div class="pf_snippet" v-if="item.snippet">
                        {{ item.snippet }}
                    </div>
                    <div class="pf_link" v-if="item.linkedin_id">
                        LinkedIn: <a :href="item.linkedin_id" v-if="item.linkedin_id" target="blank">{{item.linkedin_id}}</a>
                    </div>
                    <div class="pf_phone" v-if="item.phone">
                        Phone: {{ item.phone }}
                    </div>
                
                    <div class="pf_all_uris" v-if="item.all_uris">
                        Links:
                        <ul v-for="link in item.all_uris">
                            <li><a :href="link" target="blank">{{link}}</a></li>
                         </ul>
                    </div>
                    <div class="buttons">
                        <LightSearch :item="item" /> 
                        
                        <button v-if="isLeadId(item)" class="button is-primary" @click="toLead(isLeadId(item))">Go to Lead and Get E-mail</button>
                        <button v-else class="button" @click="addLead(item, results.query, results.id)">Create Lead and Get E-mail</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import SaveCsv from '@/components/services/SaveCsv'
    import LightSearch from '@/components/services/LightSearch'
    import ProjectEmailCreate from '@/components/services/ProjectEmailCreate'

    export default {
        name: 'SearchResult',
        components: {SaveCsv, LightSearch, ProjectEmailCreate},
		data() {
            return {
                results: {
                    "created_at": "",
                    "created_by": "",
                    "id": 0,
                    "query": "",
                    "result": {
                        "number_of_results": 0,
                        "persons": []
                    },
                    "team": 0
                },
                leadLiLinks: []
            }
        },
        computed: {


        },
        created() {
            this.getLeadsLiLinks() //linkedin urls for all leads to compare with results
            this.getSearchResult()
        },
        methods: {
            async getLeadsLiLinks() {
                await axios
                .get("/lms/leads/li-links/")
                .then(response => {
                    this.leadLiLinks = response.data
                    //console.log("this.leadLiLinks", this.leadLiLinks)                    
                })
            },

            isLeadId(item) {
                if (item.lead_id) {
                    return item.lead_id
                }

                //console.log("isLeadId item:", item)
                for (let ll of this.leadLiLinks) {
                        //console.log("items:", ll)
                        if (item.linkedin_id === ll.linkedin) {
                            //console.log("True!!", ll.linkedin)
                            return ll.id
                            }
                } 
                return false
                
            },

		    async getSearchResult() {
		    	this.$store.commit('setIsLoading', true)

                const searchID = this.$route.params.id

                await axios
                	.get(`/lms/search/result/${searchID}`)
                    .then(response => {
                        this.results = response.data
                        console.log("results", this.results)
                        
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })

		    	this.$store.commit('setIsLoading', false)
		    },

            toLead(lead_id) {
                this.$router.push(`/dashboard/leads/${lead_id}`)
            },

            async addLead(item, searchTitle, searchID) {
                this.$store.commit('setIsLoading', true)
                //console.log('AddLead:', item, searchTitle)
                //console.log(typeof item.email)
                //create string for lead__info

                let info_str = ''
                if (item.location) {
                    info_str += '\nLocation: '+item.location
                }
                if (item.phone) {
                    info_str += '\nPhones:' + item.phone + ', '
                }
                if (item.twitter_id) {
                    info_str += item.twitter_id+'\n'
                }
                if (item.quora_id) {
                    info_str += item.quora_id+'\n'
                }

                if (item.all_uris) {
                    //`${all_uris}`
                    info_str += '\n'+`${item.all_uris}`.split(',').join('\n')+'\n'
                }
                if (item.g_links) {
                    //`${all_uris}`
                    info_str += '\n'+`${item.g_links}`.split(',').join('\n')+'\n'
                }
                
                const lead = {
                    company: item.company,
                    contact_person: item.name,
                    //email: item.email[0] ? item.email.length > 0 : '',
                    //phone: item.phone[0] ? item.phone.length > 0 : '',
                    email: '',
                    phone: '',
                    job: item.job,
                    //location: item.location,
                    linkedin: item.linkedin_id,
                    snippet: item.snippet,
                    //estimated_value: 1,
                    //confidence: 1,
                    //status: "new",
                    //priority: "medium",
                    from_search: searchTitle,
                    from_search_id: searchID,
                    info: info_str
                }
                let resp = ''
                await axios
                    .post('/lms/leads/', lead)
                    .then(response => {
                        // toast({
                        //     message: 'The lead was added',
                        //     type: 'is-success',
                        //     dismissible: true,
                        //     pauseOnHover: true,
                        //     duration: 2000,
                        //     position: 'bottom-right',
                        // })
                        resp = response.data
                        item.lead_id = resp.id
                        //console.log('lead_id', item.lead_id)
                        //console.log('RESP', response)
                        //this.$router.push('/dashboard/leads')
                    })
                    .catch(error => {
                        console.log(error)
                    })
                //console.log('resp', resp)    
                //await this.addComment(resp, item.all_uris)
            
                this.$store.commit('setIsLoading', false)
            },

            async xlsExport() {
                this.$store.commit('setIsLoading', true)
                await axios
                    .get('/lms/search/save-xls')
                    .then(response => {
                        //console.log(response.data.link)
                        window.location.replace(process.env.VUE_APP_LMS_URL+'/lms/search/save-xls/'+response.data.link)
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again!')
                        }
                    })
                
                
                this.$store.commit('setIsLoading', false)
            },

        },
    }
    
</script>
<style>
    .pse-bttn {
        display: inline-block;
        justify-content: center;
        padding-bottom: calc(0.5em - 1px);
        padding-left: 1em;
        padding-right: 1em;
        padding-top: calc(0.5em - 1px);
        text-align: center;
        white-space: nowrap;
    }
    .ucases {
        padding-bottom: 24px;
    }
    .sm {
        font-size: smaller;
        color:  #777;
    }
    .res_pf {
        margin:12px;
        border-bottom: 1px solid #aaa;
        min-height:160px;
    }
    .pf_photo {
        max-width: 120px;
        max-height: 120px;
        
    }
    .pf {
        overflow: hidden;
    }
    .pf_photo_w {
        min-width:120px;
        min-height: 10px;
        margin-right: 20px;
    }
    @media screen and (min-width: 700px) {
        .pf_photo_w {
        float:left;
        }
    }
    .pf_snippet {
        border-top: 1px dashed #aaa ;
        border-bottom: 1px dashed #aaa ;
        font-size: smaller;
    }

    .sr-hints {
        padding: 1.25rem;
        margin-top: 12px;
    }
    .hint{
        display: inline-block;
        padding:  9px;
        color:  #999;
        font-size: smaller;
    }

</style>
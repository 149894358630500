<template>
	<div class="container">
        <div class="columns">
            <div class="column is-8 is-offset-2">
            	
            	<div v-if="confErr == 'er'">
    				<h1 class="title">Expired or invalid Link</h1>  		
            	</div>
            	<div v-else-if="confErr == 'ok'">
					<h1 class="title">Email Confirmed!</h1>
					<p>&nbsp;</p>
					<router-link :to="{ path: '/log-in' }"><button class="button is-warning">Login</button></router-link>
				</div>
				<div v-else>
					<h1 class="title">Email verification...</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
        name: 'ConfirmEmail',
        data() {
            return {
                confErr: 'wait',
            }
        },
        async created(){

	       	let body = {
	      		uid: this.$route.params.uid,
	      		token: this.$route.params.token
	      	}

	    	await axios
	            .post("/lms/users/activation/",  body)
	            .then(response => {
	            	//this.$router.push('/log-in')
	            	this.confErr = 'ok'
	            })
		        .catch(error => {
		        	this.confErr = 'er'
		        })
    		console.log(this.$route.params)
        },
        methods: {
        	
        }
    }
</script>
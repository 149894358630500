<template>
    <div class="container">
        <div class="columns is-multiline" v-if="lead.created_at">
            <div class="column is-12">
                <a href="/dashboard/leads/">Leads</a> &raquo; 
                <h1 class="title">{{ lead.contact_person }}</h1>
            </div>
            <div class="column is-6">
                <p><strong>Job/position: </strong>{{ lead.job }}</p>
                <p><strong>Company: </strong>{{ lead.company }}</p>
                <p><strong>LinkedIn: </strong><br>
                    <a :href="lead.linkedin" v-if="lead.linkedin" target="_blank">{{lead.linkedin}}</a>
                </p>
                <EmailSaas :item="lead" />
                <LightSearch :item="lead" />
            </div>
            <div class="column is-6">
                <div class="box">

                    <!--
                    <template v-if="lead.assigned_to">
                        <p><strong>Assigned to: </strong>{{ lead.assigned_to.first_name }} {{ lead.assigned_to.last_name }}</p>
                    </template>
                    -->
                    <p><strong>Status: </strong>{{ lead.status }}</p>
                    <p><strong>Priority: </strong>{{ lead.priority }}</p>
                    <!--
                    <p><strong>Confidence: </strong>{{ lead.confidence }}</p>
                    <p><strong>Estimated value: </strong>{{ lead.estimated_value }}</p>
                    -->
                    <p><strong>Created at: </strong>{{ lead.created_at.replace("T"," ").split(".")[0] }}</p>
                    <p><strong>Modified at: </strong>{{ lead.modified_at.replace("T"," ").split(".")[0]  }}</p>
                    <p v-if="lead.from_search_id"><strong>Found as: </strong> 
                        <a :href="`/search/${lead.from_search_id}`">{{lead.from_search}}</a></p>
                    <p v-else><strong>Found as: </strong>{{lead.from_search}}</p>
                </div>
            </div>
            <hr>
            <div class="column is-12">
                <h3>Brief:</h3>
                <div class="ld_info">
                    {{ lead.snippet}}
                </div>
                <h3>Notes:</h3>
                <div class="ld_info">
                    {{ lead.info}}
                </div>
                <div class="buttons">
                    <router-link :to="{ name: 'EditLead', params: { id: lead.id }}" class="button is-warning">Edit</router-link>
                    <!--<button @click="convertToClient" class="button is-info">Convert to client</button>-->
                    <button @click="deleteLead" class="button is-danger">Delete</button>
                </div>
            </div>
            <!-- comments to lead --
            <hr>

            <div class="column is-12">
                <h2 class="subtitle">Comments</h2>
                    
                <router-link :to="{ name: 'AddComment', params: { id: lead.id }}" class="button is-success mb-6">
                Add comment</router-link>
            
                <div
                    class="box"
                    v-for="comment in comments"
                    v-bind:key="comment.id"
                >
                    <h3 class="is-size-4">{{ comment.name }}</h3>

                    <div class="cmt_b" v-html="comment.body"></div>

                    <router-link :to="{ name: 'EditComment', params: { id: lead.id, comment_id: comment.id }}" class="button is-warning mt-6">Edit comment</router-link>
                </div>
            </div>
            !-- /comments to lead -->

        </div>
        <div v-else>
            <h1 class="title">Not found</h1>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import LightSearch from '@/components/services/LightSearch'
    import EmailSaas from '@/components/services/EmailSaas'

    export default {
        name: 'Lead',
        components: {LightSearch, EmailSaas},
        data() {
            return {
                lead: {},
                // comments: []
            }
        },
        mounted() {
            this.getLead()
            //console.log(this.lead)
        },
        methods: {
            /*
            clickComment() {
            },
            */
            async deleteLead() {
                this.$store.commit('setIsLoading', true)

                const leadID = this.$route.params.id

                await axios
                    .post(`/lms/leads/delete_lead/${leadID}/`)
                    .then(response => {
                        this.$router.push('/dashboard/leads')
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
            async getLead() {
                this.$store.commit('setIsLoading', true)

                const leadID = this.$route.params.id

                await axios
                    .get(`/lms/leads/${leadID}/`)
                    .then(response => {
                        this.lead = response.data

                        console.log("Lead.vue GetLead:", this.lead)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                /*
                await axios
                    .get(`/lms/comments/?lead_id=${leadID}`)
                    .then(response => {
                        this.comments = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
                */
                this.$store.commit('setIsLoading', false)
            },
            /*
            async convertToClient() {
                this.$store.commit('setIsLoading', true)

                const leadID = this.$route.params.id
                const data = {
                    lead_id: leadID
                }

                await axios
                    .post(`/lms/convert_lead_to_client/`, data)
                    .then(response => {
                        console.log('converted to client')

                        this.$router.push('/dashboard/clients')
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            }
            */
        }
    }
</script>
<style>
    h3 {
        font-weight:  700;
    }
    .cmt_b {
        white-space: pre-wrap;
        /*max-height: 180px;
        overflow: hidden;*/
    }
    .ld_info {
        white-space: pre-wrap;
    }
</style>

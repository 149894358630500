<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Leads</h1>

                <!--
                <router-link 
                    to="/dashboard/leads/add"
                    v-if="$store.state.team.max_leads > num_leads"
                >Add lead</router-link>

                <div
                    class="notification is-danger"
                    v-else
                >
                    You have reached the top of your limitations. Please upgrade!
                </div>
                -->

                <!--
                <router-link 
                    to="/dashboard/leads/add"
                >Add lead</router-link>
                <hr>
                -->
                <div v-if="currentPage === 1">
                    <p><strong>Here is a full list of contacts from all your search results. You can apply a filter by typing any key word or words in the field below.</strong></p> 
                    <br />
                
                    <form @submit.prevent="getLeads">
                        <div class="field has-addons">
                            <div class="control">
                                <input type="text" class="input" v-model="query">
                            </div>
                            <div class="control">
                                <button class="button is-success">Search</button>
                                <button class="button is-neutral clr" @click="srchClear()">Clear</button>
                            </div>
                        </div>
                    </form>
                 
                </div>
            </div>

            <div class="column is-12">
                <div v-for="lead in leads" class="ld_itm">
                    <LeadInList :lead="lead" :project="0"/>
                </div>


                <div class="buttons">
                    <button class="button is-light" @click="goToPreviousPage()" v-if="showPreviousButton">Previous</button>
                    <button class="button is-light" @click="goToNextPage()" v-if="showNextButton">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import LeadInList from '@/components/services/LeadInList'

    export default {
        name: 'Leads',
        components: {LeadInList},
        data() {
            return {
                leads: [],
                showNextButton: false,
                showPreviousButton: false,
                currentPage: 1,
                query: '',
                num_leads: 0
            }
        },
        mounted() {
            this.getLeads()
        },
        methods: {
            srchClear() {
                this.query = ''
            },
            goToNextPage() {
                this.currentPage += 1
                this.getLeads()
            },
            goToPreviousPage() {
                this.currentPage -= 1
                this.getLeads()
            },
            async getLeads() {
                this.$store.commit('setIsLoading', true)

                this.showNextButton = false
                this.showPreviousButton = false

                await axios
                    .get(`/lms/leads/`)
                    .then(response => {
                        console.log('/lms/leads/ response.data', response.data)
                        this.num_leads = response.data.count
                    })

                await axios
                    .get(`/lms/leads/?page=${this.currentPage}&search=${this.query}`)
                    //.get(`/lms/leads/?search=${this.query}`)
                    .then(response => {
                        this.leads = response.data.results

                        if (response.data.next) {
                            this.showNextButton = true
                        }

                        if (response.data.previous) {
                            this.showPreviousButton = true
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            }
        }
    }
</script>
<style>
    .ld_itm {
        padding: 16px;
        margin: 12px 0;
        background-color: #f0f0f0;
        border-bottom: 1px solid #fff;
    }
    .clr{
        display: inline;
    }
</style>
<template>
    <div class="container">
        <div class="columns">
            <div class="column is-8 is-offset-2">

<h1 class="title"><strong>Find respondents fast with AI powered search tool b2bsearch.co</strong></h1>

<p><strong>Get hundreds of respondents’ contacts in no time and download them for unlimited use. Or dive deep and learn everything about a respondent by enriching his profile.</strong></p>

<LandingAccordion />


                
            </div>
        </div>
    </div>
</template>

<script>
    import LandingAccordion from '@/components/LandingAccordion'

    export default {
        name: 'LandingMR',
        components: {LandingAccordion},
        
    }
</script>

<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12 content">


                <h1 class="title"><strong>Find the right people for your business needs with&nbsp;B2BSearch</strong></h1>

                <p><strong>AI-powered search platform for sourcing leads, talent, and market research respondents based on your targeted criteria</strong></p>
                <br />
                <div class="columns fullw" style="text-align: center">
                    <div class="column forcols">
                        <strong>FOR RECRUITERS</strong>
                        <br />
                        <br />
                        <p>Find top candidates for open roles by expanding your search beyond job title  - search by tech stack, skills, certifications, and experience. Enrich contacts and engage candidates all in one place.</p>
                    </div>
                    <div class="column forcols">
                        <strong>FOR BUSINESS DEVELOPMENT MANAGERS</strong>
                        <br />
                        <br />
                        <p>Acquire potential customers’ contact information to promote and expand your business.</p>
                    </div>
                    <div class="column forcols">
                        <strong>FOR MARKET RESEARCHERS</strong>
                        <br />
                        <br />
                        <p>Discover hard-to-reach respondents for your market research from various fields and industries.</p>
                    </div>
                </div>
                <br />
                <LandingAccordion />

            </div>
        </div>
    </div>
</template>

<script>

    import LandingAccordion from '@/components/LandingAccordion'

    export default {
        name: 'HomeGuest',
        components: {LandingAccordion},
        
    }
</script>

<style scoped lang="scss">
    // ------ youtube -------------
        .embed-responsive {
            position: relative;
            display: block;
            height: 0;
            padding: 0;
            overflow: hidden;
        }

        .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
        .embed-responsive-16by9 {
            padding-bottom: 56%;
        }
    // ------ youtube -------------

    //------------------- 3 col table ----------------------

        
        .forcols {
            background-color: #f0f0f0;
            border-block: solid;
        }
        
        .fullw {
          box-shadow: 0 0 0 100vmax #f0f0f0;
          clip-path: inset(0 -100vmax); 
        }
    // ------------------- 3col table----------------------
</style>


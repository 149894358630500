<template>
	<p><b>Important!</b></p>
	<p>Use this button only if you clearly understand the consequences!</p>
	<p>The process can take several minutes. You will be redirected to Project page on completion.</p>
	<p>&nbsp;</p>
	<button class="button is-warning" @click="mailList">&#8694;&nbsp;All Emails</button>
	<p>&nbsp;</p>

	
    <div class="notification is-danger" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>
<script>
	import axios from 'axios'
	export default {
		name: 'ProjectFall',
		data () { return {
			errors: [],
		}},
		methods: {
			async mailList() {
                this.$store.commit('setIsLoading', true)
                const project_id = window.location.pathname.split('/')[2]
                console.log(project_id)
                
                await axios
                	.post(`/lms/project/${project_id}/mail-list`, project_id)
                    .then(response => {
                    	console.log(response.data)
                        this.$router.push(`/project/${project_id}`)
					})
                    .catch(error => {
                    	this.$store.commit('setIsLoading', false)
                    	console.log(error)
                        if (error.response) {
                            this.errors.push(`Probably, process takes too long. (err ${error.response.staus}). Please, click the button [All Emails] again to continue search.`)
                        } else if (error.message) {
                            this.errors.push('Probably, process takes too long. Please, click the button [All Emails] again to continue search.')
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
		}
	}
</script>